import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CommonAPI, DailyAPI } from "../../axios/api";
import moment from "moment";

import ModuleFileTransfer from "../../components/fileTransfer";
import ModuleBreadCrumb from "../../components/module/breadCrumb";
import ModuleTableEmpty from "../../components/module/table/empty";
import ModuleTableSort, {
  sortParams,
} from "../../components/module/table/sort";
import DailyPopCopy from "./popCopy";
import { FormCheckbox } from "../../components/formElement";
import { useAbortedEffect } from "../../components/hooks";
import {
  unshippedOrderImportColumns,
  unshippedOrderExportColumns,
  newOrderImportColumns,
  newOrderExportColumns,
  wayfairOrderImportColumns,
  wayfairOrderExportColumns,
  dfOrderImportColumns,
  dfOrderExportColumns,
} from "../../service/columns/daily/importOrder";
import { system_table } from "../../service/system";
import {
  getPageSetting,
  setPageSetting,
  formatOptions,
  checkFormUpdate,
  checkColumnUpdate,
} from "../../service/common";
import {
  createColumns,
  createSchema,
  formatTemplateColumns,
  ModuleTablePaginator,
} from "../../service/table";
import {
  option_function_type,
  option_daily_import_type,
  option_ship_service_level,
  option_ship_state,
  option_price_designation,
  option_sales_channel,
  option_bool_true_false,
  option_bool_yes_no,
  option_store_name,
  option_order_status,
  option_ship_method,
  option_ship_speed,
  option_currency,
} from "../../service/option";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import { classNames } from "primereact/utils";

export default function DailyImportOrder(props) {
  const dispatch = useDispatch();
  const crumbItem = [{ label: "Daily" }, { label: "匯入訂單" }];
  const [reload, setReload] = useState({ list: false });
  // 註銷(全選)
  const [revokeChecked, setRevokeChecked] = useState(false);
  // 今日是否無訂單
  const [completeData, setCompleteData] = useState({});
  // 列表資料
  const [tbData, setTbData] = useState([]);
  const [tbTotal, setTbTotal] = useState(0);
  const [tbFirstIndex, setTbFirstIndex] = useState(0);
  const [tbRows, setTbRows] = useState(system_table.size);
  const [tbColumns, setTbColumns] = useState([]);
  const [tbSortField, setTbSortField] = useState([]);
  // 篩選條件
  const defaultFilter = {
    page: 1,
    size: system_table.size,
    isTotal: false,
    isrevoked: 0,
  };
  const [filterData, setFilterData] = useState(defaultFilter);
  const [search, setSearch] = useState({});
  // 彈出視窗
  const [isPop, setIsPop] = useState({
    copy: false, // 編輯
  });
  const [popOption, setPopOption] = useState({
    type: "", // 開啟的pop
  });
  const [optionData, setOptionData] = useState({});
  // 預期出貨量欄位
  const expectQtyKey = {
    unshippedOrder: "quantity_purchased",
    wayfairOrder: "quantity",
    dfOrder: "item_quantity",
  };
  // tab
  const [tabData, setTabData] = useState([]);
  const [tabActive, setTabActive] = useState(0);
  // 預設模組資料
  const defTemplate = {
    unshippedOrder: {
      import: unshippedOrderImportColumns,
      export: unshippedOrderExportColumns,
    },
    newOrder: {
      import: newOrderImportColumns,
      export: newOrderExportColumns,
    },
    wayfairOrder: {
      import: wayfairOrderImportColumns,
      export: wayfairOrderExportColumns,
    },
    dfOrder: {
      import: dfOrderImportColumns,
      export: dfOrderExportColumns,
    },
  };
  // 匯入匯出 模組資料
  const [moduleData, setModuleData] = useState({
    importTemplate: {},
    exportTemplate: {},
  });
  // 表單設定
  const defaultValues = {
    checkAll: false,
    data: [],
  };
  // 表單欄位驗證條件
  const [schema, setSchema] = useState(
    yup
      .object({
        data: yup.array().of(
          yup.object().shape({
            // title: yup.string().required("必填"),
            // content: yup.string().required("必填"),
            // img_url: yup.string().required("必填"),
          })
        ),
      })
      .required()
  );
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  // 開啟 彈出視窗
  const openPop = (options) => {
    setPopOption(options);
    setIsPop({
      ...isPop,
      [options.type]: true,
    });
  };

  // 關閉 彈出視窗
  const closePop = (options) => {
    setIsPop((state) => ({
      ...state,
      [options.type ?? popOption.type]: false,
    }));

    // if (options?.reload) {
    //   setReload((state) => ({
    //     ...state,
    //     list: true,
    //   }));
    // }
  };

  // 帶入 tab 資料
  useEffect(() => {
    setTabData(option_daily_import_type);
  }, [option_daily_import_type]);

  // tab 切換
  const tabChange = (e) => {
    setTabActive(e.index);

    setCompleteData({});
    setTbData([]);
    setTbTotal(0);

    let temp = { ...filterData };
    setFilterData(temp);
    setSearch(temp);
    setTbFirstIndex(0);
  };

  useAbortedEffect((signal) => {
    CommonAPI.getWarehouse({
      data: { page: -1, status: 1 },
      options: { signal },
    }).then((result) => {
      let { Response, data, total, message } = result;

      if (Response === 1 && data) {
        let temp = formatOptions(
          {
            warehouse: data,
          },
          { uniqueKey: "id", showLabel: "name" }
        );

        formatOtherOptions(temp);
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }
    });
  }, []);

  const formatOtherOptions = (data) => {
    let temp = {
      ...data,
      ...formatOptions(
        {
          ship_service_level: option_ship_service_level,
          price_designation: option_price_designation,
          sales_channel: option_sales_channel,
          bool_true_false: option_bool_true_false,
          bool_yes_no: option_bool_yes_no,
          store_name: option_store_name,
          order_status: option_order_status,
          ship_method: option_ship_method,
          ship_speed: option_ship_speed,
          currency: option_currency,
        },
        { uniqueKey: "value", showLabel: "label" }
      ),
      ...formatOptions(
        {
          bool_yes_no_transfer: JSON.parse(JSON.stringify(option_bool_yes_no)),
          price_designation_transfer: JSON.parse(
            JSON.stringify(option_price_designation)
          ),
          bool_true_false_transfer: JSON.parse(
            JSON.stringify(option_bool_true_false)
          ),
        },
        { uniqueKey: "label", showLabel: "value" }
      ),
      ...formatOptions(
        {
          ship_state: option_ship_state,
        },
        { uniqueKey: "code", showLabel: "code" }
      ),
      ...formatOptions(
        {
          ship_country: JSON.parse(JSON.stringify(option_ship_state)),
        },
        { uniqueKey: "name", showLabel: "name" }
      ),
      ...formatOptions(
        {
          destination_country: JSON.parse(JSON.stringify(option_ship_state)),
        },
        { uniqueKey: "code", showLabel: "name" }
      ),
    };

    setOptionData(temp);
  };

  // 表格頁數切換觸發
  const onPage = (e) => {
    const startIndex = e.first,
      rows = e.rows;

    // 同一頁面，不刷新
    if (startIndex === tbFirstIndex && rows === tbRows) {
      return;
    }

    setTbFirstIndex(startIndex);
    setTbRows(e.rows);

    let temp = {
      ...search,
      page: Math.floor(startIndex / e.rows) + 1,
      size: rows,
      isTotal: rows === tbTotal,
    };

    setFilterData(temp);
    setSearch(temp);
  };

  // 取 該頁搜尋參數
  useEffect(() => {
    if (tabData.length === 0) return;

    let result = getPageSetting([
      `/daily/importOrder/${tabData[tabActive].value}`,
    ]);

    setTbFirstIndex(
      result && result.params.page >= 0
        ? (result.params.page - 1) * result.params.size
        : 0
    );
    if (result) {
      setTbRows(result.params.size);
      setFilterData((state) => ({ ...state, ...result.params }));
    }
    setSearch(result ? result.params : filterData);
  }, [tabData]);

  // 更新篩選條件
  const changeFilter = ({ target }) => {
    let { name, value } = target;

    setFilterData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  // 判斷是否搜尋
  useEffect(() => {
    let isSearch = Object.keys(search).length > 0;
    if (!isSearch) {
      return;
    }

    setReload((state) => ({ ...state, list: true }));
  }, [search]);

  useAbortedEffect(
    (signal) => {
      if (!reload.list) {
        return;
      }

      let function_typeList = [1, 2, 3, 4];
      let params = {
        function_typeList: function_typeList.join(","),
      };

      return DailyAPI.getTodayOrderComplete({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, total, message } = result;

        if (Response === 1 && data) {
          let temp = {};
          function_typeList.forEach((item) => {
            temp[item] = { status: 0 };
          });
          data.forEach((item) => (temp[item.function_type] = item));
          setCompleteData(temp);
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }
      });
    },
    [reload]
  );

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) {
        return;
      }

      dispatch(setProgress(true));

      getListData({ type: "list", signal });
    },
    [reload]
  );

  const getListData = ({ type = "list", signal }) => {
    let params = {
      ...search,
      page: search.isTotal || type === "export" ? -1 : search.page,
      size: search.isTotal ? -1 : search.size,
    };

    params = sortParams({ params, sortField: tbSortField });

    let promise,
      activeTab = tabData[tabActive].value;
    if (activeTab === "unshippedOrder") {
      promise = DailyAPI.getUnshippedOrderList({
        data: params,
        options: { signal },
      });
    } else if (activeTab === "newOrder") {
      promise = DailyAPI.getNewOrderList({
        data: params,
        options: { signal },
      });
    } else if (activeTab === "wayfairOrder") {
      promise = DailyAPI.getWayFairOrderList({
        data: params,
        options: { signal },
      });
    } else if (activeTab === "dfOrder") {
      promise = DailyAPI.getDFOrderList({
        data: params,
        options: { signal },
      });
    }

    if (type === "list") {
      setPageSetting({ params, page: `/daily/importOrder/${activeTab}` });
    } else if (type === "export") {
      params.page = -1;
      params.size = -1;
    }
    delete params.isTotal;

    return promise.then((result) => {
      let { Response, data, total, message } = result;

      if (Response === 1 && data) {
        if (type === "list") {
          if (search.isTotal) {
            setTbRows(total);
          }

          setTbData(data);
          setTbTotal(total);
          reset({ checkAll: false, data: data });
          setReload((state) => ({
            ...state,
            list: false,
          }));

          dispatch(setProgress(false));
        } else {
          return data;
        }
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );

        dispatch(setProgress(false));
      }
    });
  };

  // 取得回傳的模板資料
  const getTemplate = ({ importTemplate = {}, exportTemplate = {} } = {}) => {
    setModuleData({ importTemplate, exportTemplate });
  };

  // 設定列表欄位
  useEffect(() => {
    let activeTab = tabData[tabActive]?.value ?? "unshippedOrder";
    let entries = Object.values(
      moduleData.exportTemplate.settings ?? defTemplate[activeTab]?.export
    );
    if (entries.length === 0) return;

    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    // 整理表格欄位
    let tempColumns = createColumns({
      type: "list",
      saveType: "single",
      saveFun: saveColumnAction,
      activeTemplate: entries,
      defaultTemplate: defTemplate[activeTab]?.export,
      formSetting: setting,
      optionData: optionData,
      tbRows,
    });

    if (activeTab !== "newOrder") {
      tempColumns.unshift({
        field: "isrevoked",
        header: () => (
          <FormCheckbox
            className="m-0"
            setting={setting}
            data={{
              name: "checkAll",
              label: "註銷",
              type: "boolean",
              value: true,
              changeFun: (e) => checkChange("all", e),
            }}
          />
        ),
        style: {
          width: 70,
          minWidth: 70,
        },
        frozen: true,
        alignFrozen: "left",
        body: (data, { rowIndex }) => (
          <>
            {data.is_rejected !== 1 && data.status === 1 ? (
              <FormCheckbox
                className="m-0"
                setting={setting}
                data={{
                  name: `data[${rowIndex % tbRows}].isrevoked`,
                  label: "",
                  type: "boolean",
                  value: true,
                  changeFun: (e) => checkChange("single", e),
                }}
              />
            ) : null}
          </>
        ),
      });
      tempColumns.unshift({
        field: "action",
        header: "動作",
        style: {
          width: 75,
          minWidth: 75,
        },
        frozen: true,
        alignFrozen: "left",
        body: (data, { rowIndex }) => (
          <>
            <Button
              type="button"
              label="複製"
              className="p-button-primary p-button-sm px-3 py-1 mr-2"
              onClick={() => checkCopy({ rowIndex: rowIndex % tbRows })}
            />
          </>
        ),
      });
    }

    setTbColumns(tempColumns);
  }, [moduleData, setting, optionData, tabActive, tbData, tbRows]);

  // 設定列表欄位驗證
  useEffect(() => {
    let entries = Object.values(moduleData.exportTemplate.settings ?? {});
    if (entries.length === 0 || tbData.length) return;

    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    // 整理表格欄位
    let tempSchemas = createSchema({
      type: "list",
      activeTemplate: entries,
      defaultTemplate:
        defTemplate[tabData[tabActive]?.value ?? "unshippedOrder"]?.export,
      yup: yup,
    });

    setSchema(
      yup
        .object({
          data: yup.array().of(yup.object().shape(tempSchemas)),
        })
        .required()
    );
  }, [moduleData, tbData, tabActive]);

  /*
   * 勾選-切換
   * 以下條件才能勾選 checkbox：
   * 非註銷(is_rejected != 1)
   * 狀態為 New(status = 1)
   * 有填寫 Warehouse(warehouse_id)
   * 有選擇 Shipping Method(shipping_method)
   * 有選擇 Pallet(vc_inventoryList)
   * 選擇的 pallet 數量足夠(pallet_enough = true)
   */
  const checkChange = (type, e) => {
    let tempData = getValues();
    if (!tempData.data || tempData.data.length === 0) return;

    let total = 0, // 可勾選數量
      checked = 0; // 勾選數量
    if (type === "all") {
      tempData.data.forEach((item) => {
        item.isrevoked = tempData.checkAll;

        if (item.isrevoked) checked++;
      });
      reset(tempData);
    } else {
      tempData.data.forEach((item) => {
        total++;
        if (item.isrevoked) checked++;
      });

      if (tempData.checkAll !== !(total !== checked)) {
        tempData.checkAll = !(total !== checked);
        reset(tempData);
      }
    }

    setRevokeChecked(checked > 0);
  };

  // 確認是否可複製
  const checkCopy = ({ rowIndex }) => {
    let submitData = getValues(),
      data = submitData.data[rowIndex];

    if (data[expectQtyKey[tabData[tabActive]?.value]] > 1) {
      openPop({ type: "copy", data: data });
    } else {
      dispatch(
        setToast({
          severity: "warn",
          summary: "數量不足",
          detail: "",
        })
      );
    }
  };

  // 註銷
  const revokeAction = () => {
    dispatch(setProgress(true));

    let submitData = getValues();
    let jsonData = { data: [] };
    submitData.data.forEach((item) => {
      if (item.isrevoked) {
        jsonData.data.push({
          id: item.id,
          isrevoked: 1,
        });
      }
    });

    saveMultiAction({ jsonData: jsonData, msg: "註銷" });
  };

  // 整理單筆資料
  const saveColumnAction = ({ rowIndex, apiKey }) => {
    let submitData = getValues(),
      data = submitData.data[rowIndex];
    let jsonData = {
      id: data.id,
      [apiKey]: data[apiKey],
    };

    if (!checkColumnUpdate(tbData[rowIndex], data, apiKey)) return;

    dispatch(setProgress(true));

    saveSingleAction({ jsonData: jsonData, msg: "變更" });
  };

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    let updateData = [];
    tbData.forEach((item, index) => {
      let data = submitData.data[index];
      let update_flag = checkFormUpdate({
        newData: data,
        oldData: item,
      });
      if (update_flag) {
        let temp = {
          id: item.id,
          ...formatTemplateColumns({
            type: "export",
            template:
              defTemplate[tabData[tabActive]?.value ?? "unshippedOrder"]
                ?.export,
            data: [data],
          })[0],
        };

        updateData.push(temp);
      }
    });

    if (updateData.length === 0) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "無資料變更",
          detail: "",
        })
      );
      dispatch(setProgress(false));
      return;
    }

    saveMultiAction({ jsonData: { data: updateData }, msg: "變更" });
  };

  // 儲存資料
  const saveMultiAction = ({ jsonData, msg = "" }) => {
    let promise,
      activeTab = tabData[tabActive].value;
    if (activeTab === "unshippedOrder") {
      promise = DailyAPI.updateUnshippedOrderMulti({ data: jsonData });
    } else if (activeTab === "newOrder") {
      promise = DailyAPI.updateNewOrderMulti({ data: jsonData });
    } else if (activeTab === "wayfairOrder") {
      promise = DailyAPI.updateWayFairOrderMulti({ data: jsonData });
    } else if (activeTab === "dfOrder") {
      promise = DailyAPI.updateDFOrderMulti({ data: jsonData });
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: `${msg}成功`,
            detail: "",
          })
        );
        setReload((state) => ({
          ...state,
          list: true,
        }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  const saveSingleAction = ({ jsonData, msg = "" }) => {
    let promise,
      activeTab = tabData[tabActive].value;
    if (activeTab === "unshippedOrder") {
      promise = DailyAPI.updateUnshippedOrderSingle({ data: jsonData });
    } else if (activeTab === "newOrder") {
      promise = DailyAPI.updateNewOrderSingle({ data: jsonData });
    } else if (activeTab === "wayfairOrder") {
      promise = DailyAPI.updateWayFairOrderSingle({ data: jsonData });
    } else if (activeTab === "dfOrder") {
      promise = DailyAPI.updateDFOrderSingle({ data: jsonData });
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        setReload((state) => ({
          ...state,
          list: true,
        }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 儲存匯入資料
  const saveImportAction = ({ jsonData, resetFileIpt }) => {
    let promise,
      activeTab = tabData[tabActive].value;
    if (activeTab === "unshippedOrder") {
      promise = DailyAPI.setDailyUnshippedImportData({ data: jsonData });
    } else if (activeTab === "newOrder") {
      promise = DailyAPI.setDailyNewOrderImportData({ data: jsonData });
    } else if (activeTab === "wayfairOrder") {
      promise = DailyAPI.setDailyWayFairImportData({ data: jsonData });
    } else if (activeTab === "dfOrder") {
      promise = DailyAPI.setDailyDFImportData({ data: jsonData });
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "匯入成功",
            detail: "",
          })
        );
        resetFileIpt();
        setReload((state) => ({
          ...state,
          list: true,
        }));
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "匯入失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 今日無訂單
  const setOrderComplete = () => {
    dispatch(setProgress(true));

    let jsonData = {
      function_type: option_function_type[tabData[tabActive]?.value],
    };

    DailyAPI.setOrderComplete({ data: jsonData }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "今日無訂單",
            detail: "",
          })
        );

        let temp = { ...completeData };
        temp[jsonData.function_type] = {
          function_type: jsonData.function_type,
          status: 1,
        };
        setCompleteData(temp);
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: "變更失敗",
            detail: "",
          })
        );
      }

      dispatch(setProgress(false));
    });
  };

  return (
    <>
      <ModuleBreadCrumb className="mb-3 border-none" crumbItem={crumbItem} />

      <TabView
        className="mb-3"
        activeIndex={tabActive}
        onTabChange={(e) => tabChange(e)}
        // scrollable
      >
        {tabData.map((item, index) => (
          <TabPanel
            key={`tab_item_${index}`}
            header={
              <div className="relative">
                {completeData[option_function_type[item.value]]?.status === 0 &&
                  item.value !== "newOrder" && (
                    <i
                      className="pi pi-circle-fill absolute top-0 right-0 text-xs text-red-400"
                      style={{
                        transform: "scale(0.7)",
                        margin: "-.6rem -1.1rem 0 0",
                      }}
                    ></i>
                  )}

                <div>{item.label}</div>
              </div>
            }
          ></TabPanel>
        ))}
      </TabView>

      <div className="grid mr-0">
        <div className="col-12 sm:col-4 md:col-6 pr-0">
          {tabData[tabActive]?.value !== "newOrder" && (
            <>
              {completeData[option_function_type[tabData[tabActive]?.value]]
                ?.status === 0 && (
                <Button
                  className="p-button-primary ml-auto"
                  type="button"
                  label="今日無訂單"
                  onClick={() => setOrderComplete()}
                />
              )}

              <Button
                className={classNames("p-button-danger px-3", {
                  "ml-2":
                    completeData[
                      option_function_type[tabData[tabActive]?.value]
                    ]?.status === 0,
                })}
                type="button"
                label="註銷"
                onClick={() => revokeAction()}
              />
            </>
          )}
        </div>
        <div className="col-12 sm:col-8 md:col-6 pr-0">
          <ModuleFileTransfer
            key={tabData[tabActive]?.value}
            setting={{ importFlag: true, exportFlag: true }}
            moduleType={tabData[tabActive]?.value}
            importColumns={
              defTemplate[tabData[tabActive]?.value ?? "unshippedOrder"]?.import
            }
            exportColumns={
              defTemplate[tabData[tabActive]?.value ?? "unshippedOrder"]?.export
            }
            exportData={tbData}
            optionData={optionData}
            saveAction={saveImportAction}
            getTemplate={getTemplate}
            getAllData={getListData}
          />
        </div>
      </div>

      <div className="mt-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DataTable
            value={tbData}
            className={classNames("size-sm", {
              "table-empty": tbData.length === 0,
            })}
            size="normal"
            paginator
            paginatorTemplate={ModuleTablePaginator}
            currentPageReportTemplate="Total {totalRecords} items"
            lazy
            first={tbFirstIndex}
            totalRecords={tbTotal}
            onPage={onPage}
            rows={tbRows}
            scrollable
            scrollHeight="calc(100vh - 20rem)"
            scrollDirection="both"
            responsiveLayout="scroll"
            emptyMessage={system_table.empty}
          >
            {tbColumns?.map((item, index) => (
              <Column
                key={`${item.field}_${index}`}
                {...item}
                {...(!["isrevoked", "action"].includes(item.field)
                  ? {
                      header: (
                        <div className="flex align-items-center">
                          {typeof item.header === "function"
                            ? item.header()
                            : item.header}
                          {item.header && (
                            <ModuleTableSort
                              field={item.field}
                              sortField={tbSortField}
                              setSortField={setTbSortField}
                              rawTbData={[]}
                              tbData={tbData}
                              setTbData={setTbData}
                              sortAction={() =>
                                setReload((state) => ({ list: true }))
                              }
                            />
                          )}
                        </div>
                      ),
                    }
                  : {})}
              />
            ))}
          </DataTable>

          {tbData.length === 0 ? (
            <ModuleTableEmpty />
          ) : (
            // <div className="flex -mt-6">
            //   <Button className="px-3 ml-auto" type="submit" label="Save" />
            // </div>
            <></>
          )}
        </form>
      </div>

      <Dialog
        header={
          <h3 className="my-0">{popOption.type === "copy" ? "Copy" : ""}</h3>
        }
        headerClassName="border-300"
        contentClassName="p-0"
        visible={isPop.copy}
        className="max-w-30rem w-11"
        onHide={() => closePop({ type: popOption.type })}
        // closable={false}
      >
        {isPop.copy ? (
          <DailyPopCopy
            closePrePop={closePop}
            prePopOption={popOption}
            setPreReload={setReload}
            qtyKey={expectQtyKey[tabData[tabActive]?.value]}
            activeTab={tabData[tabActive]?.value}
          />
        ) : null}
      </Dialog>
    </>
  );
}
