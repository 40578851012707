import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

import ModuleTableSort, { formatTbSort } from "../module/table/sort";
import { system_table } from "../../service/system";
import { createColumns, createSchema } from "../../service/table";
import { parseExcelDateToDate } from "../../service/common";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

export default function FileTransferPopImport(props) {
  const {
    isPop,
    prePopOption,
    closePrePop,
    importColumns, // 匯入預設欄位
    activeModule, // 生效的模板資料
    importData, // 匯入資料
    optionData, // 選單資料
    saveAction, // 儲存(函示)
    resetFileIpt, // 重置檔案選擇器
  } = props;
  const dispatch = useDispatch();
  // 列表資料
  const [rawData, setRawData] = useState([]);
  const [tbData, setTbData] = useState([]);
  const [tbFirstIndex, setTbFirstIndex] = useState(0);
  const [tbColumns, setTbColumns] = useState([]);
  const [tbSortField, setTbSortField] = useState([]);
  // 表單設定
  const defaultValues = {
    data: [],
  };
  // 表單欄位驗證條件
  const [schema, setSchema] = useState(
    yup
      .object({
        data: yup.array().of(
          yup.object().shape({
            // title: yup.string().required("必填"),
            // content: yup.string().required("必填"),
            // img_url: yup.string().required("必填"),
          })
        ),
      })
      .required()
  );
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  // 設定列表欄位
  useEffect(() => {
    let entries = Object.values(activeModule.importTemplate.settings ?? {});
    if (entries.length === 0) return;

    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    // 整理表格欄位
    let tempColumns = createColumns({
      type: "import",
      saveType: "multi",
      activeTemplate: entries,
      defaultTemplate: importColumns,
      formSetting: setting,
      optionData: optionData,
    });
    setTbColumns(tempColumns);
  }, [activeModule, setting]);

  // 設定列表欄位驗證
  useEffect(() => {
    let entries = Object.values(activeModule.importTemplate.settings ?? {});
    if (entries.length === 0 || tbData.length) return;

    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    // 整理表格欄位
    let tempSchemas = createSchema({
      type: "import",
      activeTemplate: entries,
      defaultTemplate: importColumns,
      yup: yup,
    });

    setSchema(
      yup
        .object({
          data: yup.array().of(yup.object().shape(tempSchemas)),
        })
        .required()
    );
  }, [activeModule, tbData]);

  // 將資料塞入 hook-form
  useEffect(() => {
    let entries = Object.values(activeModule.importTemplate.settings ?? {});

    let temp = [];
    importData.data.forEach((item, index) => {
      let row = { serial_idx: index };
      entries.forEach((column) => {
        let columnSetting = importColumns[column.apiKey];
        let value = item[column.value];
        if (value !== null && value !== undefined) {
          if (
            columnSetting.uiType === "select" &&
            columnSetting.optionTransfer
          ) {
            value =
              optionData[`${columnSetting.options}_transfer_obj`][value]
                ?.showLabel;
          } else if (
            columnSetting.uiType === "number" ||
            (columnSetting.uiType === "select" &&
              columnSetting.optionType === "number")
          ) {
            value = Number(value);
          } else if (
            columnSetting.uiType === "date" &&
            columnSetting.dateFormatLabel
          ) {
            if (typeof value === "number") value = parseExcelDateToDate(value);
            else if (value.indexOf("JST") !== -1) {
              value = value.replace("JST", "CDT");
              value = moment(value)
                .subtract(14, "hours")
                .format(columnSetting.dateFormatLabel);
            } else
              value = value
                ? moment(value).format(columnSetting.dateFormatLabel)
                : null;
          } else {
            value = String(value);
          }
        } else {
          value = null;
        }

        row[column.apiKey] = value;
      });

      temp.push(row);
    });

    setRawData([...temp]);
    setTbData([...temp]);
    reset({ data: temp });
  }, [importData]);

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    closePrePop({ type: "import" });
    saveAction({ jsonData: submitData, resetFileIpt: () => resetFileIpt() });
  };

  // 顯示錯誤訊息
  const onError = (errs) => {
    let msg = "";
    errs.data.forEach((err, errIdx) => {
      msg += `第${errIdx + 1}列：\n`;
      let rowData = Object.entries(err);
      rowData.forEach(([key, val], colIdx) => {
        msg += `${key}：${val.message}`;

        if (colIdx < rowData.length - 1) msg += "\n";
      });

      if (errIdx < errs.data.length - 1) msg += "\n";
    });

    dispatch(
      setToast({
        severity: "error",
        summary: "資料有誤",
        detail: msg,
        sticky: true,
      })
    );
  };

  return (
    <>
      <div className="p-3">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <DataTable
            value={tbData}
            className="size-sm"
            size="normal"
            stripedRows
            scrollable
            scrollDirection="both"
            emptyMessage={system_table.empty}
          >
            {tbColumns?.map((item, index) => (
              <Column
                key={`${item.field}_${index}`}
                field={item.field}
                className={item.className}
                body={item.body}
                style={item.style}
                header={
                  <div className="flex align-items-center">
                    {typeof item.header === "function"
                      ? item.header()
                      : item.header}
                    <ModuleTableSort
                      field={item.field}
                      sortField={tbSortField}
                      setSortField={setTbSortField}
                      rawTbData={rawData}
                      tbData={getValues("data")}
                      setTbData={setTbData}
                      sortAction={({ data, rawData, field, setData }) =>
                        formatTbSort({
                          data,
                          rawData,
                          field,
                          setData,
                          isForm: true,
                          reset,
                          trigger,
                        })
                      }
                    />
                  </div>
                }
              />
            ))}
          </DataTable>
          <div className="flex mt-2">
            <Button
              type="button"
              label="Close"
              className="p-button-secondary p-button-outlined ml-auto px-3"
              onClick={() => closePrePop({ type: "import" })}
            />
            <Button className="px-3 ml-2" type="submit" label="Save" />
          </div>
        </form>
      </div>
    </>
  );
}
