/* 
  @params name: 欄位顯示名稱
  @params value: 實際 匯入/匯出 欄位名稱
  @params apiKey: api 對應的欄位名稱
  @params uiType: 欄位 UI 類型 (string、number、date、select)
  @params options: 資料來源
  @params optionType: 存資料庫類型 (string、number)
  @params optionTransfer: 資料是否需要轉換 (若資料不符合選單 value，則需要轉換)
  @params maxKey: 最大數字 資料來源 key
  @params maxNum: 最大數字
  @params dateFormat: 日期格式
  @params isShow: 是否顯示欄位
  @params importDisabled: 匯入是否開放編輯
  @params listDisabled: 列表是否開放編輯
  @params importUI: 匯入是否顯示UI元件
  @params listUI: 列表是否顯示UI元件
  @params required: 是否必填
  @params width: 欄位寬度
  @params order: 排序

  ImportColumns: 匯入欄位
  ListColumns: 列表欄位
*/
export const unshippedOrderImportColumns = {
  order_id: {
    name: "Order ID",
    value: "order-id",
    apiKey: "order_id",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 1,
  },
  order_item_id: {
    name: "Order Item ID",
    value: "order-item-id",
    apiKey: "order_item_id",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 2,
  },
  purchase_date: {
    name: "Purchase Date",
    value: "purchase-date",
    apiKey: "purchase_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 3,
  },
  payments_date: {
    name: "Payments Date",
    value: "payments-date",
    apiKey: "payments_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 4,
  },
  reporting_date: {
    name: "Reporting Date",
    value: "reporting-date",
    apiKey: "reporting_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 5,
  },
  promise_date: {
    name: "Promise Date",
    value: "promise-date",
    apiKey: "promise_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 6,
  },
  days_past_promise: {
    name: "Days Past Promise",
    value: "days-past-promise",
    apiKey: "days_past_promise",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 7,
  },
  buyer_email: {
    name: "Buyer Email",
    value: "buyer-email",
    apiKey: "buyer_email",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 8,
  },
  buyer_name: {
    name: "Buyer Name",
    value: "buyer-name",
    apiKey: "buyer_name",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 9,
  },
  buyer_phone_number: {
    name: "Buyer Phone Number",
    value: "buyer-phone-number",
    apiKey: "buyer_phone_number",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 10,
  },
  sku: {
    name: "Sku",
    value: "sku",
    apiKey: "sku",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 11,
  },
  product_name: {
    name: "Product Name",
    value: "product-name",
    apiKey: "product_name",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 12,
  },
  quantity_shipped: {
    name: "Quantity Shipped",
    value: "quantity-shipped",
    apiKey: "quantity_shipped",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 13,
  },
  quantity_purchased: {
    name: "Quantity Purchased",
    value: "quantity-purchased",
    apiKey: "quantity_purchased",
    uiType: "number",
    min: 0,
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: true,
    width: 170,
    order: 14,
  },
  quantity_to_ship: {
    name: "Quantity To Ship",
    value: "quantity-to-ship",
    apiKey: "quantity_to_ship",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 15,
  },
  ship_service_level: {
    name: "Ship Service Level",
    value: "ship-service-level",
    apiKey: "ship_service_level",
    uiType: "select",
    options: "ship_service_level",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 16,
  },
  recipient_name: {
    name: "Recipient Name",
    value: "recipient-name",
    apiKey: "recipient_name",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 17,
  },
  ship_address_1: {
    name: "Ship Address 1",
    value: "ship-address-1",
    apiKey: "ship_address_1",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 18,
  },
  ship_address_2: {
    name: "Ship Address 2",
    value: "ship-address-2",
    apiKey: "ship_address_2",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 19,
  },
  ship_address_3: {
    name: "Ship Address 3",
    value: "ship-address-3",
    apiKey: "ship_address_3",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 20,
  },
  ship_city: {
    name: "Ship City",
    value: "ship-city",
    apiKey: "ship_city",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 21,
  },
  ship_state: {
    name: "Ship State",
    value: "ship-state",
    apiKey: "ship_state",
    uiType: "select",
    options: "ship_state",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 22,
  },
  ship_postal_code: {
    name: "Ship Postal Code",
    value: "ship-postal-code",
    apiKey: "ship_postal_code",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 23,
  },
  ship_country: {
    name: "Ship Country",
    value: "ship-country",
    apiKey: "ship_country",
    uiType: "select",
    options: "destination_country",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 24,
  },
  sales_channel: {
    name: "Sales Channel",
    value: "sales-channel",
    apiKey: "sales_channel",
    uiType: "select",
    options: "sales_channel",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 25,
  },
  is_business_order: {
    name: "Is Business Order",
    value: "is-business-order",
    apiKey: "is_business_order",
    uiType: "select",
    options: "bool_true_false",
    optionType: "number",
    optionTransfer: true,
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 26,
  },
  purchase_order_number: {
    name: "Purchase Order Number",
    value: "purchase-order-number",
    apiKey: "purchase_order_number",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 27,
  },
  price_designation: {
    name: "Price Designation",
    value: "price-designation",
    apiKey: "price_designation",
    uiType: "select",
    options: "price_designation",
    optionType: "number",
    optionTransfer: true,
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 28,
  },
  is_prime: {
    name: "Is Prime",
    value: "is-prime",
    apiKey: "is_prime",
    uiType: "select",
    options: "bool_true_false",
    optionType: "number",
    optionTransfer: true,
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 29,
  },
};

export const unshippedOrderExportColumns = {
  order_id: {
    name: "Order ID",
    value: "Order ID",
    apiKey: "order_id",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 1,
  },
  order_item_id: {
    name: "Order Item ID",
    value: "Order Item ID",
    apiKey: "order_item_id",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 2,
  },
  purchase_date: {
    name: "Purchase Date",
    value: "Purchase Date",
    apiKey: "purchase_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 3,
  },
  payments_date: {
    name: "Payments Date",
    value: "Payments Date",
    apiKey: "payments_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 4,
  },
  reporting_date: {
    name: "Reporting Date",
    value: "Reporting Date",
    apiKey: "reporting_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 5,
  },
  promise_date: {
    name: "Promise Date",
    value: "Promise Date",
    apiKey: "promise_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 6,
  },
  days_past_promise: {
    name: "Days Past Promise",
    value: "Days Past Promise",
    apiKey: "days_past_promise",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 7,
  },
  buyer_email: {
    name: "Buyer Email",
    value: "Buyer Email",
    apiKey: "buyer_email",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 8,
  },
  buyer_name: {
    name: "Buyer Name",
    value: "Buyer Name",
    apiKey: "buyer_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 9,
  },
  buyer_phone_number: {
    name: "Buyer Phone Number",
    value: "Buyer Phone Number",
    apiKey: "buyer_phone_number",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 10,
  },
  sku: {
    name: "Sku",
    value: "Sku",
    apiKey: "sku",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 11,
  },
  product_name: {
    name: "Product Name",
    value: "Product Name",
    apiKey: "product_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 12,
  },
  quantity_shipped: {
    name: "Quantity Shipped",
    value: "Quantity Shipped",
    apiKey: "quantity_shipped",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 13,
  },
  quantity_purchased: {
    name: "Quantity Purchased",
    value: "Quantity Purchased",
    apiKey: "quantity_purchased",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 170,
    order: 14,
  },
  quantity_to_ship: {
    name: "Quantity To Ship",
    value: "Quantity To Ship",
    apiKey: "quantity_to_ship",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 15,
  },
  ship_service_level: {
    name: "Ship Service Level",
    value: "Ship Service Level",
    apiKey: "ship_service_level",
    uiType: "select",
    options: "ship_service_level",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 16,
  },
  recipient_name: {
    name: "Recipient Name",
    value: "Recipient Name",
    apiKey: "recipient_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 17,
  },
  ship_address_1: {
    name: "Ship Address 1",
    value: "Ship Address 1",
    apiKey: "ship_address_1",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 18,
  },
  ship_address_2: {
    name: "Ship Address 2",
    value: "Ship Address 2",
    apiKey: "ship_address_2",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 19,
  },
  ship_address_3: {
    name: "Ship Address 3",
    value: "Ship Address 3",
    apiKey: "ship_address_3",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 20,
  },
  ship_city: {
    name: "Ship City",
    value: "Ship City",
    apiKey: "ship_city",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 21,
  },
  ship_state: {
    name: "Ship State",
    value: "Ship State",
    apiKey: "ship_state",
    uiType: "select",
    options: "ship_state",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 22,
  },
  ship_postal_code: {
    name: "Ship Postal Code",
    value: "Ship Postal Code",
    apiKey: "ship_postal_code",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 23,
  },
  ship_country: {
    name: "Ship Country",
    value: "Ship Country",
    apiKey: "ship_country",
    uiType: "select",
    options: "destination_country",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 24,
  },
  sales_channel: {
    name: "Sales Channel",
    value: "Sales Channel",
    apiKey: "sales_channel",
    uiType: "select",
    options: "sales_channel",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 25,
  },
  is_business_order: {
    name: "Is Business Order",
    value: "Is Business Order",
    apiKey: "is_business_order",
    uiType: "select",
    options: "bool_true_false",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 26,
  },
  purchase_order_number: {
    name: "Purchase Order Number",
    value: "Purchase Order Number",
    apiKey: "purchase_order_number",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 27,
  },
  price_designation: {
    name: "Price Designation",
    value: "Price Designation",
    apiKey: "price_designation",
    uiType: "select",
    options: "price_designation",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 28,
  },
  is_prime: {
    name: "Is Prime",
    value: "Is Prime",
    apiKey: "is_prime",
    uiType: "select",
    options: "bool_true_false",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 29,
  },
};

export const newOrderImportColumns = {
  order_id: {
    name: "Order ID",
    value: "order-id",
    apiKey: "order_id",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 1,
  },
  order_item_id: {
    name: "Order Item ID",
    value: "order-item-id",
    apiKey: "order_item_id",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 2,
  },
  purchase_date: {
    name: "Purchase Date",
    value: "purchase-date",
    apiKey: "purchase_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 3,
  },
  payments_date: {
    name: "Payments Date",
    value: "payments-date",
    apiKey: "payments_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 4,
  },
  buyer_email: {
    name: "Buyer Email",
    value: "buyer-email",
    apiKey: "buyer_email",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 5,
  },
  buyer_name: {
    name: "Buyer Name",
    value: "buyer-name",
    apiKey: "buyer_name",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 6,
  },
  buyer_phone_number: {
    name: "Buyer Phone Number",
    value: "buyer-phone-number",
    apiKey: "buyer_phone_number",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 7,
  },
  sku: {
    name: "Sku",
    value: "sku",
    apiKey: "sku",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 8,
  },
  product_name: {
    name: "Product Name",
    value: "product-name",
    apiKey: "product_name",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 9,
  },
  quantity_purchased: {
    name: "Quantity Purchased",
    value: "quantity-purchased",
    apiKey: "quantity_purchased",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 170,
    order: 10,
  },
  currency: {
    name: "Currency",
    value: "currency",
    apiKey: "currency",
    uiType: "select",
    options: "currency",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 11,
  },
  item_price: {
    name: "Item Price",
    value: "item-price",
    apiKey: "item_price",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 12,
  },
  item_tax: {
    name: "Item Tax",
    value: "item-tax",
    apiKey: "item_tax",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 13,
  },
  shipping_price: {
    name: "Shipping Price",
    value: "shipping-price",
    apiKey: "shipping_price",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 14,
  },
  shipping_tax: {
    name: "Shipping Tax",
    value: "shipping-tax",
    apiKey: "shipping_tax",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 15,
  },
  ship_service_level: {
    name: "Ship Service Level",
    value: "ship-service-level",
    apiKey: "ship_service_level",
    uiType: "select",
    options: "ship_service_level",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 16,
  },
  recipient_name: {
    name: "Recipient Name",
    value: "recipient-name",
    apiKey: "recipient_name",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 17,
  },
  ship_address_1: {
    name: "Ship Address 1",
    value: "ship-address-1",
    apiKey: "ship_address_1",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 18,
  },
  ship_address_2: {
    name: "Ship Address 2",
    value: "ship-address-2",
    apiKey: "ship_address_2",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 19,
  },
  ship_address_3: {
    name: "Ship Address 3",
    value: "ship-address-3",
    apiKey: "ship_address_3",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 20,
  },
  ship_city: {
    name: "Ship City",
    value: "ship-city",
    apiKey: "ship_city",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 21,
  },
  ship_state: {
    name: "Ship State",
    value: "ship-state",
    apiKey: "ship_state",
    uiType: "select",
    options: "ship_state",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 22,
  },
  ship_postal_code: {
    name: "Ship Postal Code",
    value: "ship-postal-code",
    apiKey: "ship_postal_code",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 23,
  },
  ship_country: {
    name: "Ship Country",
    value: "ship-country",
    apiKey: "ship_country",
    uiType: "select",
    options: "destination_country",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 24,
  },
  ship_phone_number: {
    name: "Ship Phone Number",
    value: "ship-phone-number",
    apiKey: "ship_phone_number",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 170,
    order: 25,
  },
  item_promotion_discount: {
    name: "Item Promotion Discount",
    value: "item-promotion-discount",
    apiKey: "item_promotion_discount",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 190,
    order: 26,
  },
  item_promotion_id: {
    name: "Item Promotion ID",
    value: "item-promotion-id",
    apiKey: "item_promotion_id",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 27,
  },
  ship_promotion_discount: {
    name: "Ship Promotion Discount",
    value: "ship-promotion-discount",
    apiKey: "ship_promotion_discount",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 190,
    order: 28,
  },
  ship_promotion_id: {
    name: "Ship Promotion ID",
    value: "ship-promotion-id",
    apiKey: "ship_promotion_id",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 29,
  },
  delivery_start_date: {
    name: "Delivery Start Date",
    value: "delivery-start-date",
    apiKey: "delivery_start_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 30,
  },
  delivery_end_date: {
    name: "Delivery End Date",
    value: "delivery-end-date",
    apiKey: "delivery_end_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 31,
  },
  delivery_time_zone: {
    name: "Delivery Time Zone",
    value: "delivery-time-zone",
    apiKey: "delivery_time_zone",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 170,
    order: 32,
  },
  delivery_instructions: {
    name: "Delivery Instructions",
    value: "delivery-Instructions",
    apiKey: "delivery_instructions",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 170,
    order: 33,
  },
  sales_channel: {
    name: "Sales Channel",
    value: "sales-channel",
    apiKey: "sales_channel",
    uiType: "select",
    options: "sales_channel",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 34,
  },
  is_business_order: {
    name: "Is Business Order",
    value: "is-business-order",
    apiKey: "is_business_order",
    uiType: "select",
    options: "bool_true_false",
    optionType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 35,
  },
  purchase_order_number: {
    name: "Purchase Order Number",
    value: "purchase-order-number",
    apiKey: "purchase_order_number",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 36,
  },
  price_designation: {
    name: "Price Designation",
    value: "price-designation",
    apiKey: "price_designation",
    uiType: "select",
    options: "price_designation",
    optionType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 37,
  },
  is_prime: {
    name: "Is Prime",
    value: "is-prime",
    apiKey: "is_prime",
    uiType: "select",
    options: "bool_true_false",
    optionType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 38,
  },
};

export const newOrderExportColumns = {
  order_id: {
    name: "Order ID",
    value: "Order ID",
    apiKey: "order_id",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 1,
  },
  order_item_id: {
    name: "Order Item ID",
    value: "Order Item ID",
    apiKey: "order_item_id",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 2,
  },
  purchase_date: {
    name: "Purchase Date",
    value: "Purchase Date",
    apiKey: "purchase_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 3,
  },
  payments_date: {
    name: "Payments Date",
    value: "Payments Date",
    apiKey: "payments_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 4,
  },
  buyer_email: {
    name: "Buyer Email",
    value: "Buyer Email",
    apiKey: "buyer_email",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 5,
  },
  buyer_name: {
    name: "Buyer Name",
    value: "Buyer Name",
    apiKey: "buyer_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 6,
  },
  buyer_phone_number: {
    name: "Buyer Phone Number",
    value: "Buyer Phone Number",
    apiKey: "buyer_phone_number",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 7,
  },
  sku: {
    name: "Sku",
    value: "Sku",
    apiKey: "sku",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 8,
  },
  product_name: {
    name: "Product Name",
    value: "Product Name",
    apiKey: "product_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 9,
  },
  item_price: {
    name: "Quantity Purchased",
    value: "Quantity Purchased",
    apiKey: "item_price",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 170,
    order: 10,
  },
  currency: {
    name: "Currency",
    value: "Currency",
    apiKey: "currency",
    uiType: "select",
    options: "currency",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 11,
  },
  item_tax: {
    name: "Item Price",
    value: "Item Price",
    apiKey: "item_tax",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 12,
  },
  quantity_purchased: {
    name: "Item Tax",
    value: "Item Tax",
    apiKey: "quantity_purchased",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 13,
  },
  shipping_price: {
    name: "Shipping Price",
    value: "Shipping Price",
    apiKey: "shipping_price",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 14,
  },
  shipping_tax: {
    name: "Shipping Tax",
    value: "Shipping Tax",
    apiKey: "shipping_tax",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 15,
  },
  ship_service_level: {
    name: "Ship Service Level",
    value: "Ship Service Level",
    apiKey: "ship_service_level",
    uiType: "select",
    options: "ship_service_level",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 16,
  },
  recipient_name: {
    name: "Recipient Name",
    value: "Recipient Name",
    apiKey: "recipient_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 17,
  },
  ship_address_1: {
    name: "Ship Address 1",
    value: "Ship Address 1",
    apiKey: "ship_address_1",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 18,
  },
  ship_address_2: {
    name: "Ship Address 2",
    value: "Ship Address 2",
    apiKey: "ship_address_2",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 19,
  },
  ship_address_3: {
    name: "Ship Address 3",
    value: "Ship Address 3",
    apiKey: "ship_address_3",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 20,
  },
  ship_city: {
    name: "Ship City",
    value: "Ship City",
    apiKey: "ship_city",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 21,
  },
  ship_state: {
    name: "Ship State",
    value: "Ship State",
    apiKey: "ship_state",
    uiType: "select",
    options: "ship_state",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 22,
  },
  ship_postal_code: {
    name: "Ship Postal Code",
    value: "Ship Postal Code",
    apiKey: "ship_postal_code",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 23,
  },
  ship_country: {
    name: "Ship Country",
    value: "Ship Country",
    apiKey: "ship_country",
    uiType: "select",
    options: "destination_country",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 24,
  },
  ship_phone_number: {
    name: "Ship Phone Number",
    value: "Ship Phone Number",
    apiKey: "ship_phone_number",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 170,
    order: 25,
  },
  item_promotion_discount: {
    name: "Item Promotion Discount",
    value: "Item Promotion Discount",
    apiKey: "item_promotion_discount",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 190,
    order: 26,
  },
  item_promotion_id: {
    name: "Item Promotion ID",
    value: "Item Promotion ID",
    apiKey: "item_promotion_id",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 27,
  },
  ship_promotion_discount: {
    name: "Ship Promotion Discount",
    value: "Ship Promotion Discount",
    apiKey: "ship_promotion_discount",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 190,
    order: 28,
  },
  ship_promotion_id: {
    name: "Ship Promotion ID",
    value: "Ship Promotion ID",
    apiKey: "ship_promotion_id",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 29,
  },
  delivery_start_date: {
    name: "Delivery Start Date",
    value: "Delivery Start Date",
    apiKey: "delivery_start_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 30,
  },
  delivery_end_date: {
    name: "Delivery End Date",
    value: "Delivery End Date",
    apiKey: "delivery_end_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 31,
  },
  delivery_time_zone: {
    name: "Delivery Time Zone",
    value: "Delivery Time Zone",
    apiKey: "delivery_time_zone",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 170,
    order: 32,
  },
  delivery_instructions: {
    name: "Delivery Instructions",
    value: "Delivery Instructions",
    apiKey: "delivery_instructions",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 170,
    order: 33,
  },
  sales_channel: {
    name: "Sales Channel",
    value: "Sales Channel",
    apiKey: "sales_channel",
    uiType: "select",
    options: "sales_channel",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 34,
  },
  is_business_order: {
    name: "Is Business Order",
    value: "Is Business Order",
    apiKey: "is_business_order",
    uiType: "select",
    options: "bool_true_false",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 35,
  },
  purchase_order_number: {
    name: "Purchase Order Number",
    value: "Purchase Order Number",
    apiKey: "purchase_order_number",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 36,
  },
  price_designation: {
    name: "Price Designation",
    value: "Price Designation",
    apiKey: "price_designation",
    uiType: "select",
    options: "price_designation",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 37,
  },
  is_prime: {
    name: "Is Prime",
    value: "Is Prime",
    apiKey: "is_prime",
    uiType: "select",
    options: "bool_true_false",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 38,
  },
};

export const wayfairOrderImportColumns = {
  po_number: {
    name: "PO Number",
    value: "PO Number",
    apiKey: "po_number",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 1,
  },
  store_name: {
    name: "Store Name",
    value: "Store Name",
    apiKey: "store_name",
    uiType: "select",
    options: "store_name",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 2,
  },
  warehouse_name: {
    name: "Warehouse Name",
    value: "Warehouse Name",
    apiKey: "warehouse_name",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 3,
  },
  po_date: {
    name: "PO Date",
    value: "PO Date",
    apiKey: "po_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 4,
  },
  must_ship_by: {
    name: "Must Ship By",
    value: "Must Ship By",
    apiKey: "must_ship_by",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 5,
  },
  backorder_date: {
    name: "Backorder Date",
    value: "Backorder Date",
    apiKey: "backorder_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 6,
  },
  order_status: {
    name: "Order Status",
    value: "Order Status",
    apiKey: "order_status",
    uiType: "select",
    options: "order_status",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 7,
  },
  item_number: {
    name: "Item Number",
    value: "Item Number",
    apiKey: "item_number",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 8,
  },
  item_name: {
    name: "Item Name",
    value: "Item Name",
    apiKey: "item_name",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 9,
  },
  quantity: {
    name: "Quantity",
    value: "Quantity",
    apiKey: "quantity",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 10,
  },
  wholesale_price: {
    name: "Wholesale Price",
    value: "Wholesale Price",
    apiKey: "wholesale_price",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 11,
  },
  ship_method: {
    name: "Ship Method",
    value: "Ship Method",
    apiKey: "ship_method",
    uiType: "select",
    options: "ship_method",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 12,
  },
  carrier_name: {
    name: "Carrier Name",
    value: "Carrier Name",
    apiKey: "carrier_name",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 13,
  },
  shipping_account_number: {
    name: "Shipping Account Number",
    value: "Shipping Account Number",
    apiKey: "shipping_account_number",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 14,
  },
  ship_to_name: {
    name: "Ship To Name",
    value: "Ship To Name",
    apiKey: "ship_to_name",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 15,
  },
  ship_to_address: {
    name: "Ship To Address",
    value: "Ship To Address",
    apiKey: "ship_to_address",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 16,
  },
  ship_to_address_2: {
    name: "Ship To Address 2",
    value: "Ship To Address 2",
    apiKey: "ship_to_address_2",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 17,
  },
  ship_to_city: {
    name: "Ship To City",
    value: "Ship To City",
    apiKey: "ship_to_city",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 18,
  },
  ship_to_state: {
    name: "Ship To State",
    value: "Ship To State",
    apiKey: "ship_to_state",
    uiType: "select",
    options: "ship_state",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 19,
  },
  ship_to_zip: {
    name: "Ship To Zip",
    value: "Ship To Zip",
    apiKey: "ship_to_zip",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 20,
  },
  ship_to_phone: {
    name: "Ship To Phone",
    value: "Ship To Phone",
    apiKey: "ship_to_phone",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 21,
  },
  inventory_at_po_time: {
    name: "Inventory at PO Time",
    value: "Inventory at PO Time",
    apiKey: "inventory_at_po_time",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 22,
  },
  inventory_send_date: {
    name: "Inventory Send Date",
    value: "Inventory Send Date",
    apiKey: "inventory_send_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 23,
  },
  ship_speed: {
    name: "Ship Speed",
    value: "Ship Speed",
    apiKey: "ship_speed",
    uiType: "select",
    options: "ship_speed",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 24,
  },
  po_datetime: {
    name: "PO Date & Time",
    value: "PO Date & Time",
    apiKey: "po_datetime",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 25,
  },
  registered_timestamp: {
    name: "Registered Timestamp",
    value: "Registered Timestamp",
    apiKey: "registered_timestamp",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 26,
  },
  packing_slip_url: {
    name: "Packing Slip URL",
    value: "Packing Slip URL",
    apiKey: "packing_slip_url",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 27,
  },
  tracking_number: {
    name: "Tracking Number",
    value: "Tracking Number",
    apiKey: "tracking_number",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 28,
  },
  ready_for_pickup_date: {
    name: "Ready for Pickup Date",
    value: "Ready for Pickup Date",
    apiKey: "ready_for_pickup_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 29,
  },
  sku: {
    name: "SKU",
    value: "SKU",
    apiKey: "sku",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 30,
  },
  destination_country: {
    name: "Destination Country",
    value: "Destination Country",
    apiKey: "destination_country",
    uiType: "select",
    options: "destination_country",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 170,
    order: 31,
  },
  depot_id: {
    name: "Depot ID",
    value: "Depot ID",
    apiKey: "depot_id",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 32,
  },
  depot_name: {
    name: "Depot Name",
    value: "Depot Name",
    apiKey: "depot_name",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 33,
  },
};

export const wayfairOrderExportColumns = {
  po_number: {
    name: "PO Number",
    value: "PO Number",
    apiKey: "po_number",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 1,
  },
  store_name: {
    name: "Store Name",
    value: "Store Name",
    apiKey: "store_name",
    uiType: "select",
    options: "store_name",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 2,
  },
  warehouse_name: {
    name: "Warehouse Name",
    value: "Warehouse Name",
    apiKey: "warehouse_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 3,
  },
  po_date: {
    name: "PO Date",
    value: "PO Date",
    apiKey: "po_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 4,
  },
  must_ship_by: {
    name: "Must Ship By",
    value: "Must Ship By",
    apiKey: "must_ship_by",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 5,
  },
  backorder_date: {
    name: "Backorder Date",
    value: "Backorder Date",
    apiKey: "backorder_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 6,
  },
  order_status: {
    name: "Order Status",
    value: "Order Status",
    apiKey: "order_status",
    uiType: "select",
    options: "order_status",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 7,
  },
  item_number: {
    name: "Item Number",
    value: "Item Number",
    apiKey: "item_number",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 8,
  },
  item_name: {
    name: "Item Name",
    value: "Item Name",
    apiKey: "item_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 9,
  },
  quantity: {
    name: "Quantity",
    value: "Quantity",
    apiKey: "quantity",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 10,
  },
  wholesale_price: {
    name: "Wholesale Price",
    value: "Wholesale Price",
    apiKey: "wholesale_price",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 11,
  },
  ship_method: {
    name: "Ship Method",
    value: "Ship Method",
    apiKey: "ship_method",
    uiType: "select",
    options: "ship_method",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 12,
  },
  carrier_name: {
    name: "Carrier Name",
    value: "Carrier Name",
    apiKey: "carrier_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 13,
  },
  shipping_account_number: {
    name: "Shipping Account Number",
    value: "Shipping Account Number",
    apiKey: "shipping_account_number",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 14,
  },
  ship_to_name: {
    name: "Ship To Name",
    value: "Ship To Name",
    apiKey: "ship_to_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 15,
  },
  ship_to_address: {
    name: "Ship To Address",
    value: "Ship To Address",
    apiKey: "ship_to_address",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 16,
  },
  ship_to_address_2: {
    name: "Ship To Address 2",
    value: "Ship To Address 2",
    apiKey: "ship_to_address_2",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 17,
  },
  ship_to_city: {
    name: "Ship To City",
    value: "Ship To City",
    apiKey: "ship_to_city",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 18,
  },
  ship_to_state: {
    name: "Ship To State",
    value: "Ship To State",
    apiKey: "ship_to_state",
    uiType: "select",
    options: "ship_state",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 19,
  },
  ship_to_zip: {
    name: "Ship To Zip",
    value: "Ship To Zip",
    apiKey: "ship_to_zip",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 20,
  },
  ship_to_phone: {
    name: "Ship To Phone",
    value: "Ship To Phone",
    apiKey: "ship_to_phone",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 21,
  },
  inventory_at_po_time: {
    name: "Inventory at PO Time",
    value: "Inventory at PO Time",
    apiKey: "inventory_at_po_time",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 22,
  },
  inventory_send_date: {
    name: "Inventory Send Date",
    value: "Inventory Send Date",
    apiKey: "inventory_send_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 23,
  },
  ship_speed: {
    name: "Ship Speed",
    value: "Ship Speed",
    apiKey: "ship_speed",
    uiType: "select",
    options: "ship_speed",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 24,
  },
  po_datetime: {
    name: "PO Date & Time",
    value: "PO Date & Time",
    apiKey: "po_datetime",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 25,
  },
  registered_timestamp: {
    name: "Registered Timestamp",
    value: "Registered Timestamp",
    apiKey: "registered_timestamp",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 26,
  },
  packing_slip_url: {
    name: "Packing Slip URL",
    value: "Packing Slip URL",
    apiKey: "packing_slip_url",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 27,
  },
  tracking_number: {
    name: "Tracking Number",
    value: "Tracking Number",
    apiKey: "tracking_number",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 28,
  },
  ready_for_pickup_date: {
    name: "Ready for Pickup Date",
    value: "Ready for Pickup Date",
    apiKey: "ready_for_pickup_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 29,
  },
  sku: {
    name: "SKU",
    value: "SKU",
    apiKey: "sku",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 30,
  },
  destination_country: {
    name: "Destination Country",
    value: "Destination Country",
    apiKey: "destination_country",
    uiType: "select",
    options: "destination_country",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 170,
    order: 31,
  },
  depot_id: {
    name: "Depot ID",
    value: "Depot ID",
    apiKey: "depot_id",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 32,
  },
  depot_name: {
    name: "Depot Name",
    value: "Depot Name",
    apiKey: "depot_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 33,
  },
};

export const dfOrderImportColumns = {
  order_id: {
    name: "Order ID",
    value: "Order ID",
    apiKey: "order_id",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 1,
  },
  order_status: {
    name: "Order Status",
    value: "Order Status",
    apiKey: "order_status",
    uiType: "select",
    options: "order_status",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 2,
  },
  warehouse_code: {
    name: "Warehouse Code",
    value: "Warehouse Code",
    apiKey: "warehouse_code",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 3,
  },
  order_place_date: {
    name: "Order Place Date",
    value: "Order Place Date",
    apiKey: "order_place_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 4,
  },
  required_ship_date: {
    name: "Required Ship Date",
    value: "Required Ship Date",
    apiKey: "required_ship_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 170,
    order: 5,
  },
  ship_method: {
    name: "Ship Method",
    value: "Ship Method",
    apiKey: "ship_method",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 6,
  },
  ship_method_code: {
    name: "Ship Method Code",
    value: "Ship Method Code",
    apiKey: "ship_method_code",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 7,
  },
  ship_to_name: {
    name: "Ship To Name",
    value: "Ship To Name",
    apiKey: "ship_to_name",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 8,
  },
  ship_to_address_line_1: {
    name: "Ship To Address Line 1",
    value: "Ship To Address Line 1",
    apiKey: "ship_to_address_line_1",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 9,
  },
  ship_to_address_line_2: {
    name: "Ship To Address Line 2",
    value: "Ship To Address Line 2",
    apiKey: "ship_to_address_line_2",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 10,
  },
  ship_to_address_line_3: {
    name: "Ship To Address Line 3",
    value: "Ship To Address Line 3",
    apiKey: "ship_to_address_line_3",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 11,
  },
  ship_to_city: {
    name: "Ship To City",
    value: "Ship To City",
    apiKey: "ship_to_city",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 12,
  },
  ship_to_state: {
    name: "Ship To State",
    value: "Ship To State",
    apiKey: "ship_to_state",
    uiType: "select",
    options: "ship_state",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 13,
  },
  ship_to_zip_code: {
    name: "Ship To ZIP Code",
    value: "Ship To ZIP Code",
    apiKey: "ship_to_zip_code",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 14,
  },
  ship_to_country: {
    name: "Ship To Country",
    value: "Ship To Country",
    apiKey: "ship_to_country",
    uiType: "select",
    options: "destination_country",
    optionType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 15,
  },
  phone_number: {
    name: "Phone Number",
    value: "Phone Number",
    apiKey: "phone_number",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 16,
  },
  is_it_gift: {
    name: "Is it Gift?",
    value: "Is it Gift?",
    apiKey: "is_it_gift",
    uiType: "select",
    options: "bool_yes_no",
    optionType: "number",
    optionTransfer: true,
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 17,
  },
  item_cost: {
    name: "Item Cost",
    value: "Item Cost",
    apiKey: "item_cost",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 18,
  },
  sku: {
    name: "SKU",
    value: "SKU",
    apiKey: "sku",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 19,
  },
  asin: {
    name: "ASIN",
    value: "ASIN",
    apiKey: "asin",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 20,
  },
  item_title: {
    name: "Item Title",
    value: "Item Title",
    apiKey: "item_title",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 21,
  },
  item_quantity: {
    name: "Item Quantity",
    value: "Item Quantity",
    apiKey: "item_quantity",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 22,
  },
  gift_message: {
    name: "Gift Message",
    value: "Gift Message",
    apiKey: "gift_message",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 23,
  },
  tracking_id: {
    name: "Tracking ID",
    value: "Tracking ID",
    apiKey: "tracking_id",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 200,
    order: 24,
  },
  shipped_date: {
    name: "Shipped Date",
    value: "Shipped Date",
    apiKey: "shipped_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: true,
    required: false,
    width: 150,
    order: 25,
  },
};

export const dfOrderExportColumns = {
  order_id: {
    name: "Order ID",
    value: "Order ID",
    apiKey: "order_id",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 1,
  },
  order_status: {
    name: "Order Status",
    value: "Order Status",
    apiKey: "order_status",
    uiType: "select",
    options: "order_status",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 2,
  },
  warehouse_code: {
    name: "Warehouse Code",
    value: "Warehouse Code",
    apiKey: "warehouse_code",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 3,
  },
  order_place_date: {
    name: "Order Place Date",
    value: "Order Place Date",
    apiKey: "order_place_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 4,
  },
  required_ship_date: {
    name: "Required Ship Date",
    value: "Required Ship Date",
    apiKey: "required_ship_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 170,
    order: 5,
  },
  ship_method: {
    name: "Ship Method",
    value: "Ship Method",
    apiKey: "ship_method",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 6,
  },
  ship_method_code: {
    name: "Ship Method Code",
    value: "Ship Method Code",
    apiKey: "ship_method_code",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 7,
  },
  ship_to_name: {
    name: "Ship To Name",
    value: "Ship To Name",
    apiKey: "ship_to_name",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 8,
  },
  ship_to_address_line_1: {
    name: "Ship To Address Line 1",
    value: "Ship To Address Line 1",
    apiKey: "ship_to_address_line_1",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 9,
  },
  ship_to_address_line_2: {
    name: "Ship To Address Line 2",
    value: "Ship To Address Line 2",
    apiKey: "ship_to_address_line_2",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 10,
  },
  ship_to_address_line_3: {
    name: "Ship To Address Line 3",
    value: "Ship To Address Line 3",
    apiKey: "ship_to_address_line_3",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 11,
  },
  ship_to_city: {
    name: "Ship To City",
    value: "Ship To City",
    apiKey: "ship_to_city",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 12,
  },
  ship_to_state: {
    name: "Ship To State",
    value: "Ship To State",
    apiKey: "ship_to_state",
    uiType: "select",
    options: "ship_state",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 13,
  },
  ship_to_zip_code: {
    name: "Ship To ZIP Code",
    value: "Ship To ZIP Code",
    apiKey: "ship_to_zip_code",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 14,
  },
  ship_to_country: {
    name: "Ship To Country",
    value: "Ship To Country",
    apiKey: "ship_to_country",
    uiType: "select",
    options: "destination_country",
    optionType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 15,
  },
  phone_number: {
    name: "Phone Number",
    value: "Phone Number",
    apiKey: "phone_number",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 16,
  },
  is_it_gift: {
    name: "Is it Gift?",
    value: "Is it Gift?",
    apiKey: "is_it_gift",
    uiType: "select",
    options: "bool_yes_no",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 17,
  },
  item_cost: {
    name: "Item Cost",
    value: "Item Cost",
    apiKey: "item_cost",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 18,
  },
  sku: {
    name: "SKU",
    value: "SKU",
    apiKey: "sku",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 19,
  },
  asin: {
    name: "ASIN",
    value: "ASIN",
    apiKey: "asin",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 20,
  },
  item_title: {
    name: "Item Title",
    value: "Item Title",
    apiKey: "item_title",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 21,
  },
  item_quantity: {
    name: "Item Quantity",
    value: "Item Quantity",
    apiKey: "item_quantity",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 22,
  },
  gift_message: {
    name: "Gift Message",
    value: "Gift Message",
    apiKey: "gift_message",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 23,
  },
  tracking_id: {
    name: "Tracking ID",
    value: "Tracking ID",
    apiKey: "tracking_id",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 200,
    order: 24,
  },
  shipped_date: {
    name: "Shipped Date",
    value: "Shipped Date",
    apiKey: "shipped_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: false,
    width: 150,
    order: 25,
  },
};
